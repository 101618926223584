import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IntroSect from "./compCards/IntroSect";
const TermConditionIntro = () => {
  return (
    <div
      className="intro"
   >
      <Container fluid
        style={{
        //   backgroundImage: `url(${intro})`,
          textAlign: "center",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // borderRadius: "20px",
          padding: "60px 20px",
          height:"100%",
        }}
      >
          <div className="intro-overlay"></div>
        <Row style={{zIndex:"2"}}>
          <IntroSect
            head1="Terms & Conditions "
            head2="Terms & Conditions"
          />
        </Row>
</Container>
    </div>
  );
};
export default TermConditionIntro;
