import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import Services from './Services';
import ai1 from '../../assets/services/AI/1.jpeg';
import ai2 from '../../assets/services/AI/2.jpeg';
// import s1 from '../../assets/services/services1.png'
import s2 from '../../assets/services/Appdevelopment.avif'
import s3 from '../../assets/services/devops.jpg'
import s4 from '../../assets/services/dm.jpeg'
import s5 from '../../assets/services/graphicdesign.jpg'
import s6 from '../../assets/services/pd.jpeg'
import s7 from '../../assets/services/swdevlopment.jpeg'
import s8 from '../../assets/services/uiux.avif'
import s9 from '../../assets/services/webdevelopment.jpg'
import { useLocation } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css";
const ServiceDetails = () => {

  useEffect(() => {
   
  }, []);

  // const [selectedService, setSelectedService] = useState('Website Development');
  const servicesList = [
    'Website Development',
    'App Development',
    'Custom Software Development',
    'Digital Marketing',
    'UI / UX Design',
    'Generative AI',
    'DEVOPS',
    'Graphics Designing',
    'Product Development',
    'IT Solutions',
    'Networking Services',
    'Cloud Services'
  ];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryService = queryParams.get('service');
  const initialService = servicesList.includes(queryService) ? queryService : 'Website Development';
  const [selectedService, setSelectedService] = useState(initialService);

  useEffect(() => {
    const service = queryParams.get('service');
    if (service && service !== selectedService) {
      setSelectedService(service);
    }
  }, [location.search]);

  // Define content for each service
  const serviceContent = {
    'Website Development': {
      title: 'Crafting Innovative Digital Experiences',
      description: 'At our company, we are dedicated to delivering top-tier web development solutions tailored to meet the unique needs of your business. Our expertise spans a wide range of web technologies and practices, ensuring that we create high-performing websites that are not only visually appealing but also functionally robust. We specialize in designing and developing responsive websites that offer seamless user experiences across all devices, from desktops to smartphones. Our services include custom website design, where we craft unique, eye-catching layouts that reflect your brand’s identity and values. Our development process involves implementing cutting-edge technologies to build dynamic, interactive sites with optimized performance and security. Additionally, we offer ongoing maintenance and support to ensure your website remains up-to-date with the latest standards and trends. Whether you need a new website from scratch, a redesign of an existing site, or specific features and functionalities to enhance your online presence, our team of experienced developers is here to help you achieve your goals. Our commitment to excellence and attention to detail guarantee that your website will stand out in a competitive digital landscape and effectively engage your audience',
     solutionTitle:'Simplify Tech Challenges with Our Responsive Help Desk Team',
     solutions: [
        'Front-end Development',
        'Back-end Development',
        'Content Management System (CMS) Integration',
        'E-commerce Features',
        'Responsive Design',
      ],
     helpDeskTitle: 'Elevate Productivity with Our Expert Help Desk Services',
      helpDeskList:'Welcome to Bytewave, your trusted companion in the realm of IoT services. We take pride in being a dedicated partner, committed to delivering state-of-the-art IoT solutions that propel the future of your business.',
      aiImages: [s9, s7, s8,s2,s4,s6],
      helpDeskImage: ai2
    },
    'App Development': {
      title: 'Building Cutting-Edge Mobile Solutions',
      description: "We specialize in creating high-performance mobile applications tailored to meet your business needs. Our team of expert developers is proficient in building apps for both Android and iOS platforms, ensuring a seamless user experience. We focus on crafting intuitive interfaces and robust functionalities that drive user engagement and enhance your brand’s digital presence. Whether you're looking to create a new app from scratch or improve an existing one, our end-to-end mobile app development services are designed to deliver top-notch solutions. From concept to launch, we work closely with you to understand your vision and translate it into a functional, user-friendly app that stands out in the competitive mobile landscape.",
      solutionTitle:'Stay Ahead with Our Comprehensive Mobile Development Services',
      solutions: ['Native Android and iOS Development', 'Cross-Platform App Development',  'User Interface (UI) & User Experience (UX) Design','App Store Optimization (ASO)','App Maintenance & Support'],
      helpDeskTitle: 'Support for Mobile Apps',
      helpDeskList: ['Bug Fixes', 'App Updates', 'Performance Optimization'],
      aiImages: [s2,s4,s6,s9, s7, s8],
      helpDeskImage: ai2
    },
    'Custom Software Development': {
      title: 'Tailored Solutions for Unique Business Needs',
      description:"Our Custom Software Development services are designed to create tailored solutions that perfectly align with your business objectives and operational requirements. We specialize in developing bespoke software applications that address specific challenges, streamline processes, and provide a competitive edge. Our team of experienced developers works closely with you to understand your unique needs, ensuring that the final product is not only functional but also scalable and future-proof. Whether you're looking to build a new system from scratch, enhance an existing application, or integrate diverse technologies, we deliver solutions that are customized to your exact specifications. Our development process focuses on agility, allowing us to adapt to changing requirements and deliver high-quality software on time and within budget. With our custom development services, you can drive efficiency, innovation, and growth, empowering your business to thrive in a dynamic digital landscape.",
      solutionTitle:'Empower Your Business with Tailored Software Solutions',
      solutions: [

        "Custom Application Development",
"System Integration",
"Enterprise Software Solutions",
"Legacy System Modernization",
"Scalable Architecture Design"
      ],
      helpDeskTitle: 'Software Support Services',
      helpDeskList: ['Custom Development Support', 'Troubleshooting', 'User Training'],
      aiImages: [s9,s2,s4,s6, s7, s8],
      helpDeskImage: ai2
    },
    'Digital Marketing': {
      title: 'Boost Your Online Presence',
      description: "Our Digital Marketing services are designed to help businesses navigate the digital landscape and achieve measurable growth. We specialize in crafting data-driven strategies that leverage the latest trends and technologies to reach your target audience effectively. From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing and content creation, our comprehensive approach ensures that your brand stands out in a crowded marketplace. We focus on understanding your business goals and developing customized campaigns that deliver results, whether it's increasing website traffic, boosting online sales, or enhancing brand awareness. Our team of digital marketing experts is dedicated to staying ahead of the curve, continuously optimizing campaigns to maximize ROI and drive sustainable growth. Partner with us to transform your digital presence and achieve your business objectives through strategic, results-oriented marketing",
      solutionTitle:'Elevate Your Brand with Our Digital Marketing Expertise',
      solutions: ['SEO', 'PPC', 'Social Media Marketing', 'Content Creation'],
      helpDeskTitle: 'Marketing Support',
      helpDeskList: ['Campaign Management', 'Analytics', 'Performance Reporting'],
      aiImages: [s6, s7, s2,s4,s9,s8],
      helpDeskImage: ai2
    },
    'UI / UX Design': {
      title: "Driving Growth with Innovative Digital Strategies",
      description: "Our UI/UX design services focus on creating visually appealing and user-friendly interfaces that enhance user engagement and satisfaction. We combine aesthetics with functionality to deliver designs that not only look great but also provide seamless user experiences. Our services include user research, wireframing, prototyping, visual design, and usability testing. We work closely with your team to understand your target audience and business goals, ensuring that the final product resonates with users and meets your objectives. With a user-centered approach and a keen eye for detail, our team of UI/UX designers crafts interfaces that are not only beautiful but also intuitive and easy to navigate. Trust us to elevate your digital products through exceptional design and user experience.",
      solutionTitle:'Elevate Your Brand with Our Digital Marketing Expertise',
      solutions: [
        "Search Engine Optimization (SEO)",
        "Pay-Per-Click (PPC) Advertising",
        "Social Media Marketing",
        "Content Marketing",
        "Email Marketing Campaigns"
      ],
      helpDeskTitle: 'Design Support',
      helpDeskList: ['Design Consultation', 'UI/UX Audits', 'Continuous Improvement'],
      aiImages: [s2,s4,s6,s9, s7, s8],
      helpDeskImage: ai2
    },
    'Generative AI': {
      title: 'Harnessing the Power of AI to Innovate',
      description: "Our Generative AI solutions are at the forefront of technology, enabling businesses to leverage artificial intelligence to create, optimize, and innovate. We specialize in developing AI models that can generate content, automate processes, and provide insights that were previously unimaginable. Whether it's creating unique marketing content, optimizing workflows through intelligent automation, or building AI-powered products, our expertise ensures that you stay ahead of the curve. Our team of AI specialists works closely with you to understand your goals and implement AI-driven solutions that are tailored to your specific needs. With a focus on ethical AI practices and cutting-edge research, we deliver solutions that not only enhance productivity but also drive business growth in new and exciting ways.",
      solutionTitle:'Unlock New Possibilities with Our AI-Driven Solutions',
      solutions: ['Content Generation', 'AI-Powered Automation', 'Predictive Analytics', 'Natural Language Processing (NLP)','Custom AI Model Development'],
      helpDeskTitle: 'AI Support',
      helpDeskList: ['AI Model Maintenance', 'Data Integration', 'Performance Monitoring'],
      aiImages: [s9, s2,s4,s6,s7, s8],
      helpDeskImage: ai2
    },
    'DEVOPS': {
      title: 'Streamlining Development and Operations for Maximum Efficiency',
      description:"Our DevOps services bridge the gap between development and operations, enabling faster delivery of high-quality software. We focus on automating processes, improving collaboration, and fostering a culture of continuous integration and delivery. Our services include infrastructure as code, automated testing, CI/CD pipeline setup, monitoring, and performance optimization. By leveraging the latest DevOps tools and methodologies, we help your organization achieve greater agility, reduce time-to-market, and enhance the reliability of your software products. Our team of DevOps experts works closely with your development and IT teams to ensure seamless integration and alignment with your business objectives. Trust us to transform your software delivery process and drive innovation through effective DevOps practices.",
      solutionTitle:'Enhancing Your Software Delivery with DevOps Excellence',
      solutions: [
        "infrastructure as Code",
"CI/CD Pipeline Automation",
"Continuous Monitoring",
"Performance Optimization",
"Collaboration Enhancemen"
      ],
      helpDeskTitle: 'DevOps Support',
      helpDeskList: ['Pipeline Setup', 'Infrastructure Management', 'Incident Response'],
      aiImages: [s9, s7, s8,s2,s4,s6],
      helpDeskImage: ai2
    },
    'Graphic Designing': {
      title: 'Transforming Ideas into Visual Masterpieces',
      description: "Our Graphic Design services are dedicated to creating visually stunning and impactful designs that capture your brand's essence and communicate your message effectively. Whether you're looking for a complete brand identity, eye-catching marketing materials, or engaging digital graphics, our team of skilled designers is here to bring your vision to life. We specialize in a wide range of design services including logo design, brochures, social media graphics, packaging design, and more. Our approach is centered around understanding your brand and your target audience to create designs that resonate and leave a lasting impression. Utilizing the latest design software and trends, we ensure that every project is not only aesthetically pleasing but also strategically aligned with your business goals. Let us help you stand out in a crowded market with designs that are both beautiful and functional.",
      solutionTitle:'Elevating Brands with Exceptional Graphic Design',
      solutions: ["Brand Identity Design",
"Marketing Collateral",
"Digital Graphics",
"Packaging Design",
"Custom Illustrations"],
      helpDeskTitle: 'Design Support',
      helpDeskList: ['Brand Consultation', 'Design Revisions', 'Creative Strategy'],
      aiImages: [s9, s7, s8,s2,s4,s6],
      helpDeskImage: ai2
    },
    'Product Development': {
      title: 'Innovating to Bring Your Ideas to Life',
      description: "Our Product Development services are focused on transforming your innovative ideas into market-ready products. We partner with you from concept to launch, providing a full range of services including market research, product design, prototyping, and testing. Our team of experienced developers and designers work collaboratively to create products that not only meet functional requirements but also deliver exceptional user experiences. Whether you're developing a new software application, a mobile app, or a physical product, our comprehensive approach ensures that every aspect of development is handled with precision and care. We utilize the latest technologies and development methodologies to accelerate time-to-market while maintaining the highest standards of quality. Our commitment to continuous improvement and innovation helps us deliver products that are not only technologically advanced but also aligned with your business goals. Let us help you navigate the complexities of product development and bring your vision to reality.",
      solutionTitle:' Building the Future with Comprehensive Product Development',
      solutions: [
        "Concept Development & Validation",
"User-Centered Design","Rapid Prototyping",
"Agile Development",
"Quality Assurance & Testing",
      ],
      helpDeskTitle: 'Product Support',
      helpDeskList: ['Product Strategy', 'Development Assistance', 'Launch Support'],
      aiImages: [s2,s4,s6,s9, s7, s8],
      helpDeskImage: ai2
    },
  };

// Safely access selectedContent
const selectedContent = serviceContent[selectedService] || {};
console.log('Selected Content:', selectedContent); // Add this line to check the content

if (!selectedContent) {
  console.error(`Service '${selectedService}' not found in serviceContent.`);
}  
// Inline CSS styles
  const sidebarStyle = {
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const titleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '15px',
    borderBottom: '2px solid var(--primary)',
    paddingBottom: '10px',
  };

  const buttonStyle = {
    fontSize: '16px',
    fontWeight: '500',
    padding: '12px 20px',
    borderRadius: '5px',
    textAlign: 'left',
    marginBottom: '10px',
  };

  return (
    <Container>
      <Row 
       data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
>
        <Col md={8}>
          <Carousel interval={1000} prevIcon={null} nextIcon={null}>
          {selectedContent.aiImages && selectedContent.aiImages.length > 0 && (
  <Carousel interval={1000} prevIcon={null} nextIcon={null}>
    {selectedContent.aiImages.map((image, index) => (
      <Carousel.Item key={index}>
      <img
  className="d-block w-100"
  style={{ maxHeight: '30rem', maxWidth: '100%', height: 'auto' }}
  src={image}
  alt={`Slide ${index + 1}`}
/>

      </Carousel.Item>
    ))}
  </Carousel>
)}
          </Carousel>

          <Services
            serviceTitle={selectedContent.title}
            serviceSubtitle={selectedService}
            solutionTitle={selectedContent.solutionTitle}
            serviceDescription={selectedContent.description}
            aiSolutions={selectedContent.solutions}
            helpDeskTitle={selectedContent.helpDeskTitle}
            helpDeskList={selectedContent.helpDeskList}
            aiImage={selectedContent.aiImage}
            helpDeskImage={selectedContent.helpDeskImage}
          />
        </Col>

        <Col md={4}>
          <div style={sidebarStyle}>
            <h4 style={titleStyle}>Our Services</h4>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {Object.keys(serviceContent).map((service, index) => (
                <Button
                  key={index}
                  variant={selectedService === service ? 'primary' : 'outline-primary'}
                  onClick={() => setSelectedService(service)}
                  style={{
                    ...buttonStyle,
                    backgroundColor: selectedService === service ? 'var(--primary)' : 'transparent',
                    color: selectedService === service ? 'white' : 'var(--primary)',
                    borderColor: 'var(--primary)',
                  }}
                >
                  {service}
                </Button>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceDetails;
