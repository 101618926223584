import "./App.css";
import HomePage from "./pages/HomePage";
import Navbarr from "./components/Navbar";
import { Routes, Route, BrowserRouter as Router, Navigate } from "react-router-dom";
import { useState } from "react";
import ServiceDetail from "./components/ServiceDetail";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import ServicePage from "./pages/ServicePage";
import BlogPost from "./components/BlogPost";
import PortfolioPage from "./pages/PortfolioPage";
import ScrollToTop from "./components/ScrollToTop";
import TermsConditionPage from './pages/TermsConditionPage'
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import RefundPolicyPage from "./pages/RefundPolicyPage";
import ServiceDetails from "./components/Services/ServiceDetails";
function App() {
  return (
    <Router>
      <Navbarr />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        {/* <Route path="/blog" element={<BlogPage />} /> */}
        <Route path="/services" element={<ServicePage/>} />
        <Route path="/blog/:id" element={<BlogPost/>} />
        <Route path="/portfolio" element={<PortfolioPage/>} />
        <Route path="/terms-and-conditions" element={<TermsConditionPage/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
        <Route path="/refund-policy" element={<RefundPolicyPage/>} />
        <Route path="/portfolio" element={<PortfolioPage/>} />
        {/* <Route path="/services" component={<ServiceDetails/>} /> */}
       <Route path="/service/:id" element={<ServiceDetail/>} />
          </Routes>
    </Router>
  );
}
export default App;
