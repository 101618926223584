import React from 'react'
import ServiceIntro from '../components/Services/ServiceIntro'
import ServiceDetails from '../components/Services/ServiceDetails'
import FAQs from '../components/FAQs'
import Footer from '../components/Footer'
import ServicesFeatures from '../components/Services/ServicesFeatures'

const ServicePage = () => {
  return (
    <div>
      <ServiceIntro/>
      <ServiceDetails/>
      <ServicesFeatures/>
      <FAQs/>
      <Footer/>
    </div>
  )
}

export default ServicePage
