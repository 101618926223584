import React from 'react'
import Contact from '../components/Contact'
import ContactIntro from '../components/ContactIntro'
import ContactMap from '../components/ContactMap'
import Footer from '../components/Footer'
import FAQs from '../components/FAQs'

const ContactPage = () => {
  return (
    <div>
    <ContactIntro/>
      <Contact/>
      <ContactMap/>
      <FAQs/>
      <Footer/>
    </div>
  )
}

export default ContactPage
