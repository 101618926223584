import React from 'react'
import Intro from '../components/Intro'
import Services from '../components/Services'
import Provide from '../components/Provide'
import Testimonial from '../components/Testimonial'
import Counter from '../components/Counter'
import Footer from '../components/Footer'
import AboutDevelopment from '../components/AboutUs/AboutDevelopment'
import CollabLogo from '../components/CollabLogo'
import ChooseUs from '../components/ChooseUs'
import FAQs from '../components/FAQs'

const HomePage = () => {

  
  return (
    <div>
    <Intro/>
      <Services/>
      <Provide/>
      <ChooseUs/>
      <AboutDevelopment/>
      <Testimonial/>
      {/* <Counter/> */}
 
      <CollabLogo/>
      <FAQs/>

      <Footer/>
    </div>
  )
}

export default HomePage
