import React from 'react'
import AboutIntro from '../components/AboutUs/AboutIntro'
import AboutDevelopment from '../components/AboutUs/AboutDevelopment'
import About from '../components/AboutUs/About'
import Counter from '../components/Counter'
import CollabLogo from '../components/CollabLogo'
import Footer from '../components/Footer'
import Testimonial from '../components/Testimonial'
import FAQs from '../components/FAQs'
import Team from '../components/Team'

const AboutPage = () => {
  return (
    <div>
      <AboutIntro/>
      <About/>
      <AboutDevelopment/>
      {/* <Team/> */}
      {/* <Counter/> */}
      <Testimonial/>
      <CollabLogo/>
      <FAQs/>
      <Footer/>
    </div>
  )
}

export default AboutPage
