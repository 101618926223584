import React from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQsItem = ({ faq, isOpen, onToggle }) => {
  return (
    <div className="faq-item">
      <div className="faq-question" onClick={onToggle}
        style={{ color: isOpen ? 'var(--primary)' : '#333' }} 
        >
        <h4>{faq.question}</h4>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {isOpen && <p className="faq-answer">{faq.answer}</p>}
    </div>
  );
};

export default FAQsItem;
