


import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/send-email', formData);
      alert(response.data);
    } catch (error) {
      console.error('There was an error sending the email!', error);
      alert('Error sending email');
    }
  };

  return (
    <Container style={{ padding: '50px 10px' }}>
      <Row className="justify-content-center" data-aos="fade-up" data-aos-duration="7000" style={{ padding: '0 10%' }}>
        <Col md={4} sm={12} style={{ ...boxShadowStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h5 style={{color:"var(--primary)"}}>Address:</h5>
            <p>K7-Glomac Cyberjaya,<br /> 63000 Malaysia</p>
            <h5 style={{color:"var(--primary)"}}>Phone:</h5>
            <p><a href="tel:+601163533992" style={{color:"black",textDecoration:"none"}}>+6011-6353-3992</a><br /><span>(Whatsapp)</span></p>
            <h5 style={{color:"var(--primary)"}}>Email:</h5>
            <p><a href="mailto:info@smgarctic.com" style={{color:"black",textDecoration:"none"}}>info@smgarctic.com</a></p>
          </div>
        </Col>
        <Col md={8} sm={12}>
          <h2 className="sub-head" style={{ position: 'relative', bottom: '8px' }} data-aos="fade-up" data-aos-duration="1000">Contact Us</h2>
          <h2 className="head" style={{ position: 'relative', bottom: '8px' }} data-aos="fade-up" data-aos-duration="1000">Get In Touch</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formName" style={{ marginBottom: '15px' }}>
                  <Form.Control
                    type="text"
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEmail" style={{ marginBottom: '15px' }}>
                  <Form.Control
                    type="email"
                    placeholder="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formPhone" style={{ marginBottom: '15px' }}>
                  <Form.Control
                    type="text"
                    placeholder="Your Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formSubject" style={{ marginBottom: '15px' }}>
                  <Form.Control
                    type="text"
                    placeholder="Your Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    style={inputStyle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formMessage" style={{ marginBottom: '15px' }}>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Your Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                style={inputStyle}
              />
            </Form.Group>
            <button type="submit" className="button-1">Send Message</button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

// Inline styles
const boxShadowStyle = {
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  borderRadius: '8px',
  backgroundColor: '#fff',
  borderBottom: '4px solid var(--primary)',
  borderLeft: '4px solid var(--primary)',
  marginBottom: '10px',
};

const inputStyle = {
  backgroundColor: '#fff',
  border: '2px solid #e9ecef',
  borderRadius: '4px',
  padding: '10px',
};

export default Contact;
