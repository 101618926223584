import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FaTools, FaChartLine, FaShieldAlt } from 'react-icons/fa';

const Services = ({ serviceTitle, serviceSubtitle, serviceDescription, aiSolutions=[], helpDeskTitle, helpDeskList, aiImage, helpDeskImage, solutionTitle }) => {
    // Debugging: Check if aiSolutions is defined
    console.log('aiSolutions:', aiSolutions);

  return (
    <Container fluid style={{  padding: '40px 0' }}>
      <Row className="text-center mb-5">
      <Col lg={12} >
      <p  className='sub-head' style={{marginBottom:"1px"}}>{serviceSubtitle}</p>
      <h2 style={{ fontWeight: 'bold',marginBottom:"5px" }} className='head'>{serviceTitle}</h2>
      <Image
            src={aiImage} // Path passed as prop
            alt=""
            fluid
            style={{width:"100%",margin:"2rem 0"}}
          />
      
      </Col>
        <Col  lg={12}>
         
          <p style={{ maxWidth: '800px', margin: '0 auto', lineHeight: '1.6' ,fontSize:"18px"}} className='text'>
            {serviceDescription}
          </p>
        </Col>
      </Row>
      
      <Row className="align-items-center mb-5">
        <Col md={12}>
          <h3  className='sub-head' style={{textAlign:"left",fontSize:"20px",paddingBottom:"10px"}}> {solutionTitle}</h3>
          <ul style={{ listStyleType: 'none', paddingLeft: 0}}>
            {aiSolutions.map((item, index) => (
              <li key={index} className='text' style={{ lineHeight: "20px",fontSize:"18px"}}>✔ {item}</li>
            ))}
          </ul>
        </Col>
      
      </Row>
   
     
    </Container>
  );
};

export default Services;
