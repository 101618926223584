

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import i1 from '../assets/chooseus.png';
import Aos from "aos";
import "aos/dist/aos.css";
const ChooseUs = () => {


  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonStyle = {
    margin: '10px 5px',
    padding: '15px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
    textAlign: 'center',
    color: 'black',
    backgroundColor: '#fff',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  // const activeButtonStyle = {
  //   ...buttonStyle,
  //     background: "linear-gradient(90deg, #6a63ff, #876cff)", // Purple background for active state
  
  //   color: '#fff',
  // };


  const activeButtonStyle = {
    ...buttonStyle,
    background: "linear-gradient(90deg, #6a63ff, #876cff)", // Purple background for active state
    color: '#fff',
  };

  const ecomButtonStyle = {
    ...buttonStyle,
    background: hoveredButton ? '#fff' : "linear-gradient(90deg, #6a63ff, #876cff)",
    color: hoveredButton ? 'black' : '#fff',
  };
  useEffect(() => {
  }, []);

  return (
    <Container fluid style={{ backgroundColor: '#fff', padding: '7% 0' }}
     data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
>
      <Container>
        <Row 
   
>
         
          <Col lg={6} className="d-none d-lg-block">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={i1} alt="IT Solutions Illustration" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
          </Col>
          <Col lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <h5 style={{ color: 'var(--primary)', fontWeight: '600', marginBottom: '20px', fontSize: '15px' }}
            
            data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>
            WHY CHOOSE US -
            </h5>
            <h3 style={{ fontWeight: 'bold', marginBottom: '20px' }}
             data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>We’re Delivering Technology Solutions For Today & Tomorrow</h3>
            <p style={{ color: '#6c6c6c', marginBottom: '40px' }} className="text">
            Providing cutting-edge technology solutions designed to meet the needs of today while anticipating the demands of tomorrow.
            </p>
            <Row>
              <Col md={6}>
                <Button
                  variant="light"
                  style={ecomButtonStyle}
                >
                  Discovery & Strategy
                </Button>
              </Col>
              <Col md={6}>
                <Button
                   variant="light"
                  style={hoveredButton === 'Professional Services' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Professional Services')}
                  onMouseLeave={() => setHoveredButton(null)}
              
                >
              
Professional Services
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'Knowledgeable Staff' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Knowledgeable Staff')}
                  onMouseLeave={() => setHoveredButton(null)}
               
                >
                  Knowledgeable Staff
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'Quality Solution' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Quality Solution')}
                  onMouseLeave={() => setHoveredButton(null)}
               
                >
                  Quality Solution
                </Button>
              </Col>
              <Col md={6}>
                <Button
                 variant="light"
                  style={hoveredButton === 'Managed IT Service' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Managed IT Service')}
                  onMouseLeave={() => setHoveredButton(null)}
               
                 
                >
                 Managed IT Service
                 
                </Button>
              </Col>
              <Col md={6}>
                <Button
                   variant="light"
                  style={hoveredButton === 'Cloud Service' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Cloud Service')}
                  onMouseLeave={() => setHoveredButton(null)}
               
                >
                  Cloud Services
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ChooseUs;
