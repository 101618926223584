import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import intro from '../assets/home-font.png'
import { Link } from 'react-router-dom';
const Intro = () => {
  return (
    <div style={{ padding: '80px 10%' ,marginTop: "4rem"}}>
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-center text-md-left">
            <h3 style={{ fontWeight: 'bold',textAlign:"left",paddingTop:"6px" }}>Accelerating Your Business <br /> Through Innovative <span style={{ color: 'var(--primary)' }}> IT Solutions</span></h3>
            <p style={{ margin: '20px 0',textAlign:"left" }} className='text'>
            Empower your business with cutting-edge IT solutions designed to streamline operations, enhance productivity, and drive sustainable growth. Our innovative approach ensures you stay ahead in a rapidly evolving digital landscape.
                 </p>
            <div style={{display:"flex",justifyContent:"start",gap:"10px"}}>
                       
       
<Link to="/contact" style={{color:"var(--primary)",textDecoration:"none",whiteSpace: "nowrap"}}>
<button class="button-2" role="button" >
Contact Us
</button>
</Link>
<Link to="/services" style={{color:"var(--primary)",textDecoration:"none",whiteSpace: "nowrap"}}>
<button class="button-1" role="button" >Learn More
</button>
</Link>

         </div>
          </Col>
          <Col md={6} className="text-center">
            <img
              src={intro}
              alt="IT Solutions"
              style={{ width: '100%', maxWidth: '500px' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Intro;
