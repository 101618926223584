import React from 'react'
import TermConditionIntro from '../components/TermConditionIntro'
import TermCondition from '../components/TermCondition'
import Footer from '../components/Footer'

const TermsConditionPage = () => {
  return (
    <div>
      <TermConditionIntro/>
      <TermCondition/>
      <Footer/>
    </div>
  )
}

export default TermsConditionPage
