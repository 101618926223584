

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = ({ icon, title, description, id,readmore }) => {
  const [hovered, setHovered] = useState(false);

  const cardStyle = {
    padding: '30px',
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    borderRadius: '8px',
    textAlign: 'center',
    backgroundColor: hovered ? 'transparent' : '#fff',
    background: hovered ? 'linear-gradient(90deg, #6a63ff, #876cff)' : '#fff',
    color: hovered ? '#fff' : '#000',
    marginBottom: "30px",
    height: '100%',
    transition: 'background 0.3s, color 0.3s',
  };

  const iconContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
  };

  const iconStyle = {
    width: '80px',
    height: '80px',
    backgroundColor: hovered ? '#fff' : '#f3f3f3',
    color: hovered ? '#6a63ff' : 'var(--primary)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background 0.3s, color 0.3s',
    fontSize: '36px',
  };

  const titleStyle = {
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const descriptionStyle = {
    color: hovered ? '#fff' : '#6c757d',
    marginBottom: '15px',
  };

  const linkStyle = {
    color: hovered ? '#fff' : 'var(--primary)',
    textDecoration: 'underline',
    transition: 'color 0.3s', // Smooth transition for color
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      
    >
      <div style={iconContainerStyle}>
        <div style={iconStyle}>
          {icon}
        </div>
      </div>
      <h6 style={titleStyle}>{title}</h6>
      <p style={descriptionStyle} className='text'>{description}</p>
      <Link to={readmore} style={linkStyle}>Read More</Link>
    </div>
  );
};

export default ServiceCard;
