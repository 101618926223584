import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaMailBulk,
  FaPinterest,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
const IntroSect = (props) => {
  const location = useLocation();
  const iconStyle={
    backgroundColor: "var(--green)",
              fontSize: "30px",
              fontWeight: "800",
              color: "var(--white)",
              border: "2px solid var(--green)",
              borderRadius:"50%",
              margin:"0 3px",
              padding:"4px"          
  }
  return (
    <Col lg={12} style={{zIndex:"2"}}>
      <div className="intro-content" style={{position:"relative",top:"4rem"}}>
        <h1 className="heading" style={{ color: "var(--white)" }}>
          {props.head1}{" "}
          {/* <span style={{ color: "var(--white)" }}>{props.head2}</span>{" "} */}
        </h1>
        <h3  style={{color:"var(--white)",fontSize:"1.2rem",display:"flex",justifyContent:"center"}}>


  <Nav.Link  as={Link} to="/">Home</Nav.Link>

  <style>
        {`
          .custom-list li::marker {
            color: var(--secondary);           }
        `}
      </style>
      <ul className="custom-list">
        <li>{props.head2}</li>
      </ul>
        </h3>
      
     



       
        
      </div>
    </Col>
  );
};
export default IntroSect;

