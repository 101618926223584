import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import about from '../../assets/about.jpg'
import Aos from "aos";
import "aos/dist/aos.css";
const About = () => {
  useEffect(() => {
   
  }, []);
  return (
    <Container style={{ padding: '50px 10px' }} >
    <Row className="align-items-center"
     data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
>
      {/* Text Section */}
   
      {/* Image Section */}
      <Col md={6} className="text-center">
        <img
          src={about}
          alt="IT Solutions"
          style={{ width: '80%' }}
        />
      </Col>
      <Col md={6}>
        <h5 style={{ color: 'var(--primary)', fontWeight: '600', marginBottom: '20px', fontSize: '15px'}} >Who We Are -</h5>
        <h3 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
        Tailored Tech Solutions for Business Growth in Competitive Markets
        </h3>
        <p style={{ color: '#6c6c6c', marginBottom: '40px' }} className="text">
        SMG Arctic Tech specializes in delivering cutting-edge technology and creative solutions, with a focus on mobile app development, website creation, and software systems. Their services also extend to videography, photography, and marketing, providing a comprehensive approach to enhancing digital presence. With a commitment to quality, they offer a one-year warranty on software projects, ensuring client satisfaction and long-term reliability.
  </p>

        <p style={{ color: '#6c6c6c', marginBottom: '40px' }} className="text">
       
       
Their expertise lies in understanding client needs and crafting tailored solutions that drive business growth. By integrating technology with creative strategies, SMG Arctic Tech helps businesses stand out in competitive markets. </p>
        
        {/* Progress Bars */}
      
      </Col>

    </Row>
  </Container>
  )
}

export default About
