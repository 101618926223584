import React from 'react'
import { Container } from 'react-bootstrap'

const PrivacyPolicy = () => {
  return (
    <Container>
<h3 className="sub-head" style={{textAlign:"left"}}>
Privacy Policy
</h3>
<p className='text'>
SMG Arctic Tech website is owned by SMG Arctic Tech, which is a data controller of your personal data.
<br />

<br />
We have adopted this Privacy Policy, which determines how we are processing the information collected by SMG Arctic Tech, which also provides the reasons why we must collect certain personal data about you. Therefore, you must read this Privacy Policy before using SMG Arctic Tech website.
<br />
<br />

We take care of your personal data and undertake to guarantee its confidentiality and security.
<br />
<br />
Personal information we collect:
<br />

<span style={{textDecoration:"underline"}}>
NOTICE UNDER THE PERSONAL DATA PROTECTION ACT 2010

</span>
<br />

<br />
<span style={{textDecoration:"underline"}}>

NOTIS DI BAWAH AKTA PERLINDUNGAN DATA PERIBADI 2010
</span>
<br />
<br />

The Personal Data Protection Act 2010 (the “Act”), which regulates the processing of personal data in commercial transactions, applies to SMG ARCTIC TECH (SSM No. [003349596-K)]) (“our”, “us” or “we”). For the purpose of this written notice (“Notice”), the terms “personal data” and “processing” shall have the same meaning as prescribed in the Act.
<br />

Akta Perlindungan Data Peribadi 2010 (“Akta” tersebut) yang mengawal selia
<br />
<br />

pemprosesan data peribadi dalam transaksi komersial, terpakai kepada SMG ARCTIC TECH (SSM No. [003349596-K]) (“kami”). Untuk tujuan notis bertulis ini (“Notis”), terma-terma “data peribadi” dan “pemprosesan” mempunyai maksud yang sama seperti yang ditakrif dalam Akta tersebut.
<br />
<br />
<strong>

Consent/ Persetujuan
</strong>
<br />
<br />


1. This Notice serves to inform you that your personal data is being processed by us or on our behalf when you access our website
at https://smgarctic.com (“Website”), and/or use our products and services, and you hereby give your consent to the processing of your personal data.
<br />
<br />

Notis ini bertujuan untuk memaklumkan kepada anda bahawa data peribadi anda sedang diproses oleh atau bagi pihak kami apabila anda mengakses laman web kami di https://smgarctic.com ("Laman Web") dan anda dengan ini memberikan persetujuan anda bagi pemprosesan data peribadi anda.
<br />
<br />

Description of personal data/ Perihalan data peribadi
2. We may collect a variety of your personal data including but not limited to your name, date of birth, Malaysian Identification Card number, passport number, e-mail address, address, contact number, income, bank account details including specimen signatures, race, nationality, gender, marital status, education and employment
<br />
<br />

details and history including working experience and employer’s details, credit record (present or historical), source of income or financial circumstances, financial positions, activities or history including payment history and account activities, details of guarantors and legal representatives, litigation and criminal records, details and background of family members, other personal data you submitted to us through the Website, the App or otherwise and/or in connection with the use of the Website, the App or our products and services including IP address, and all other personal data we again collect from you on any subsequent occasion.
<br />
<br />

Kami mungkin mengumpul pelbagai data peribadi anda termasuk tetapi tidak terhad kepada nama, nombor Kad Pengenalan Malaysia, nombor pasport, alamat e-mel, nombor telefon, pendapatan, maklumat akaun bank termasuk contoh tandatangan, bangsa, kewarganegaraan, jantina, status perkahwinan, maklumat dan sejarah pendidikan dan perkerjaan termasuk pengalaman perkerjaan dan butiran majikan, rekod kredit (sekarang atau terdahulu), punca pendapatan atau keadaan kewangan, kedudukan kewangan, aktiviti atau sejarah termasuk sejarah pembayaran dan aktiviti akaun, maklumat penjamin dan wakil sah, rekod tindakan undang-undang dan jenayah,
<br />
<br />

maklumat dan latar belakang keluarga, data peribadi lain yang anda hantar kepada kami melalui Laman Web atau sebaliknya dan/atau berkaitan dengan penggunaan Laman Web atau mana-mana produk dan perkhidmatan kami, dan semua data peribadi lain kami sekali lagi kumpul daripada anda pada bila-bila masa kemudiannya.
<br />
<br />
<strong>

Purpose / Maksud
</strong>
<br />
<br />
3. Your personal data is being or is to be collected and further processed for: Data peribadi anda sedang atau akan dikumpulkan dan diproses selanjutnya untuk:
<br />
<br />

(a) performing our pre-contractual and contractual obligation with you;

melaksanakan obligasi pra-kontrak dan kontrak dengan anda;
<br />
<br />

(b) granting you access to the Website and/or the App and/or in connection with the use of the Website and/or the App;
memberikan anda akses kepada Laman Web dan/atau berkaitan dengan penggunaan Laman Web;
<br />
<br />

(c) assessing your application for any of our products and services;

menilai permohonan anda untuk mana-mana produk dan perkhidmatan kami;
<br />
<br />

(d) verifying your financial standing through credit reference, credit, background and/or regulatory checks;
mengesahkan kedudukan kewangan anda menerusi semakan rujukan kredit;
<br />
<br />

(e) verifying and carrying out financial transactions in relation to payments you make online;

mengesahkan dan melaksanakan transaksi kewangan berkenaan dengan bayaran online yang dibuat oleh anda;
<br />
<br />

(f) providing our products and/or services to you (or your company, if applicable) and/or in connection with our products and services;
memberikan produk dan/atau perkhidmatan kami kepada anda (atau syarikat anda, jika berkenaan) dan/atau berkaitan dengan mana-mana produk dan perkhidmatan kami;
<br />
<br />

(g) communicating with you;
berhubung dengan anda;
<br />
<br />

(h) responding to your inquiries and resolving disputes;

menjawab pertanyaan anda dan menyelesaikan pertikaian;
<br />
<br />

(i) conducting internal activities;

menjalankan kegiatan dalaman;
<br />
<br />

(j) market surveys and trend analysis;

tinjauan pasaran dan analisis kecenderungan;
<br />
<br />

(k) improving our services, content and advertising in the Website and/or the App;

meningkatkan perkhidmatan, kandungan dan pengiklanan di dalam Laman Web;
<br />
<br />

(l) providing you (or your company, if applicable) with information on our products and/or services, promotional offers and of our related corporations and business partners;
memberikan anda (atau syarikat anda, jika berkenaan) maklumat tentang produk dan/atau perkhidmatan kami, tawaran promosi dan perbadanan-perbadanan berkaitan kami dan rakan perniagaan kami;
<br />
<br />

(m) conducting other legitimate business activities;

menjalankan kegiatan-kegiatan perniagaan sah lain;
<br />
<br />

(n) complying with any legal or regulatory requirements; and

memenuhi apa-apa kehendak undang-undang atau kawal selia; dan
<br />
<br />

(o) such other purposes directly related to the foregoing,
<br />
<br />

maksud-maksud lain yang berhubungan secara langsung dengan yang tersebut di atas,
<br />
<br />

(collectively, the “Purposes”).
<br />
(secara kolektif, “Maksud-maksud” tersebut).
<br />
<br />

Source of personal data/ Sumber data peribadi
<br />
4. Your personal data is being or is to be collected: Data peribadi anda sedang atau akan dikumpulkan:
<br />
<br />

(a) from the cookies;
<br />
dari cookies;
<br />
<br />

(b) from your Malaysian Identity Card or passport;
<br />
dari Kad Pengenalan Malaysia atau pasport anda;
<br />
<br />

(c) from your bank statement;
<br />
dari penyata bank anda;
<br />
<br />

(d) when you register to have access to the Website and/or the App,
<br />

apabila anda mendaftar bagi tujuan mengakses Laman Web kami;
<br />
<br />

(e) when you log in to or use the Website and/or the App;
<br />

apabila anda log masuk ke dalam atau menggunakan Laman Web;
<br />
<br />

(f) when you contact our customer service centre;

apabila anda menghubungi pusat perkhidmatan pelanggan kami;
<br />
<br />

(g) when you submit your personal data to us for any of the Purposes;

apabila anda menghantar data peribadi anda kepada kami untuk mana-mana Maksud- maksud tersebut;
<br />
<br />
(h) when you use or sign up for our products and/or services;
<br />
<br />
(i) from public domains, credit reporting agencies, governmental departments and/or agencies, regulatory and/or statutory bodies, public registries, social media, publications and any such third party requested or authorized by you;
<br />
<br />

(j) from your mobile device via the App;
<br />
<br />
(k) from any communication between us through various means such as emails,

letter, telephone calls and social media;
<br />
<br />
( l) from all other personal data we again collect from you on any subsequent occasion; and
dari data peribadi yang lain kami sekali lagi dapati daripada anda pada bila-bila masa kemudiannya; dan
<br />
<br />
(m) from all other information that you may provide us from time to time.

dari semua maklumat lain yang anda mungkin berikan kepada kami dari masa ke semasa.
<br />

Access to, correction of and limiting the processing of personal data/ Akses kepada, pembetulan terhadap dan had pemprosesan data peribadi
<br />
<br />
5. You have the right to request access to and to request correction of your personal data and to contact us with any inquiries or complaints in respect of your personal data (including the possible choices and means for limiting the processing of your personal data or to cease or not begin processing your personal data for purposes of direct marketing) from:
<br />
<br />


Anda berhak untuk meminta akses kepada dan meminta pembetulan terhadap data peribadi anda dan untuk menghubungi kami tentang apa-apa pertanyaan atau aduan berkenaan dengan data peribadi anda (termasuk pilihan-pilihan dan cara-cara yang mungkin untuk mengehadkan pemprosesan data peribadi anda atau untuk memberhentikan atau tidak memulakan pemprosesan data peribadi anda bagi tujuan pemasaran langsung) daripada:
<br />
<br />

E-mail address/ Alamat E-mel: smgarctictech@gmail.com
<br />
<br />

6. Subject to provisions of the Act:
<br />
<br />

Tertakluk kepada peruntukan-peruntukan Akta tersebut:
<br />
<br />

(a) you may, upon payment of a prescribed fee, make a data access request in writing to us; and
anda boleh, apabila dibayar fi yang ditetapkan, membuat suatu permintaan mengakses data secara bertulis kepada kami; dan
<br />
<br />

(b) we may refuse to comply with a data access request or a data correction request and shall, by notice in writing, inform you of our refusal and the reasons of our refusal.
kami boleh enggan mematuhi permintaan mengakses data atau permintaan pembetulan data dan hendaklah, melalui notis bertulis, memaklumkan anda mengenai keengganan tersebut dan sebab-sebab bagi keengganan tersebut.
<br />
<br />
<strong>

Disclosure of personal data/ Penzahiran data peribadi
</strong>
<br />
<br />
7. We disclose or may disclose your personal data to any other users of the Website or the App, our related corporations, business partners, service providers, credit reporting agencies, our financial and professional advisors, banks, governmental departments and/or agencies, regulatory and/or statutory bodies and any such third party requested or authorized by you for any of the Purposes.

Kami menzahirkan atau boleh menzahirkan data peribadi anda kepada pengguna lain Laman Web kami, perbadanan berkaitan kami, rakan-rakan perniagaan kami, pembekal- pembekal perkhidmatan kami, agensi-agensi pelaporan kredit, penasihat kewangan atau profesional kami, bank, jabatan-jabatan dan agensi-agensi kerajaan, badan-badan kawal selia dan/atau berkanun dan dan mana-mana pihak ketiga yang diminta atau dibenarkan oleh anda untuk mana-mana Maksud-maksud tersebut.

Third party personal data/ Data peribadi pihak ketiga
<br />
<br />
8. We may require your assistance if the personal data relating to other persons is required to process your personal data for the Purposes and you hereby agree to use your best endeavors to assist us when required. In the event that personal data of any third party is supplied by you to us, you shall ensure that such third party has read this Notice and consented to us collecting his/her personal data for any of the Purposes prior to the supply of his/her personal data to us.
Kami boleh menghendaki bantuan anda jika data peribadi yang berhubungan dengan orang lain dikehendaki untuk memproses data peribadi anda untuk Maksud-maksud tersebut dan anda dengan ini bersetuju untuk menggunakan usaha terbaik anda untuk
<br />

membantu kami bila dikehendaki. Jika data peribadi pihak ketiga telah diberikan oleh anda kepada kami, anda hendaklah memastikan bahawa pihak ketiga tersebut telah membaca Notis ini dan bersetuju dengan pemprosesan data peribanya untuk mana- mana Maksud-maksud tersebut sebelum memberikan data peribadinya kepada kami.
<br />
<br />
<strong>

Obligatory personal data/ Data peribadi wajib
</strong>
<br />
<br />
9. It is obligatory that you supply us the details marked with asterisk (*) in our registration form (collectively, “compulsory personal data”). If you fail to supply us the compulsory personal data, we may refuse to process your personal data for any of the Purposes and/or result in us being unable to provide you with the services
<br />

and/or products requested.
<br />

Ia adalah wajib untuk anda memberikan kepada kami butir-butir bertanda asterisk (*) dalam borang pendaftaran online kami (secara kolektif, “data peribadi wajib”). Jika anda gagal untuk memberikan kami data peribadi wajib tersebut, kami boleh enggan untuk memproses data peribadi anda untuk mana-mana Maksud-maksud tersebut dan/atau menyebabkan kami tidak dapat memberikan anda produk dan/atau perkhidmatan yang diminta.
<br />

Transfer of personal data to places outside Malaysia/ Pemindahan data peribadi ke tempat di luar Malaysia
<br />
<br />
10. We may transfer your personal data to a place outside Malaysia and you hereby give your consent to the transfer.
<br />

Kami boleh memindahkan data peribadi anda ke sesuatu tempat di luar Malaysia dan anda dengan ini memberikan persetujuan anda tehadap pemindahan tersebut.
<br />

Accuracy of your personal data/ Ketepatan data peribadi
<br />
<br />
11. You are responsible for ensuring that the information you provide us is accurate, complete, not misleading and kept up to date. If you fail to supply us the compulsory personal data, we may cease or refuse to provide, or may be unable to provide, you with the services and/or products requested.
Anda bertanggungjawab untuk memastikan bahawa maklumat yang anda berikan kami
<br />
<br />

adalah tepat, lengkap, tidak mengelirukan dan terkini.

<br />
<br />
<strong>

Security of your personal data
</strong>

<br />
<br />
12. SMG Arctic Tech ensures that all information collected will be safely and securely stored for a maximum of 7 years. We protect your personal information by implementing technical and organisational measures that are designed to provide a level of security appropriate to the risks of processing your personal information. Those measures are, but not limited to, the following:
<br />
<br />
a. Building and maintaining a secure network or system including but not limited to the use of cloud computing service for the provisioning of our service to you;
<br />
<br />
b. Protect customer’s data including the use secure SSL/TLS communication protocol and encryption standard for data-in-transit and secure encryption
<br />
<br />
c. Implement strong access control measures such as enforcing the use of password to access system; and
<br />
<br />
d. Secure deletion of sensitive information when it is no longer needed for our record retention purposes.
<br />

13. From a user’s perspective, your password protects your user account, so we encourage you to use a unique and strong password and limit access to your mobile device or computer.
<br />
<br />

<strong>

Amendments
</strong>
<br />

<br />
14. We reserve the right to amend this Notice at any time and from time to time. Upon any such change, we will post the amended terms on the Website or the App or we may also attempt to notify you via electronic or conventional mail. Your continued access to and/or use of the Website, the App, our products and/or our services or your continued communication with us following such posting and/or
<br />

notice shall constitute your agreement to be bound by this Notice, as amended.
<br />

Conflict/ Konflik
<br />
<br />
15. In the event of any inconsistency between the English version and the Bahasa Malaysia version of this Notice, the English version shall prevail over the Bahasa Malaysia version.
Sekiranya terdapat apa-apa ketidak-konsistenan antara versi Bahasa Inggeris dan versi Bahasa Malaysia Notis ini, versi Bahasa Inggeris akan mengatasi versi Bahasa Malaysia
</p>
<h4>Legal disclosure:</h4>
<p className="text">

We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request
</p>
<h4>Contact information:</h4>

<p className="text">If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to
<a href="info@smgarctic.com" style={{color:"#252627"}}> info@smgarctic.com</a>
</p>
    </Container>
  )
}

export default  PrivacyPolicy
