import React from 'react'
import RefundPolicyIntro from '../components/RefundPolicyIntro'
import RefundPolicy from '../components/RefundPolicy'
import Footer from '../components/Footer'

const RefundPolicyPage = () => {
  return (
    <div>
    <RefundPolicyIntro/>
    <RefundPolicy/>
      
      <Footer/>
    </div>
  )
}

export default RefundPolicyPage
