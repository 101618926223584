
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import i1 from '../assets/honefron2.png';
import Aos from "aos";
import "aos/dist/aos.css";

const Provide = () => {
  const [hoveredButton, setHoveredButton] = useState(null);

  const buttonStyle = {
    margin: '10px 5px',
    padding: '15px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    width: '100%',
    textAlign: 'center',
    color: 'black',
    backgroundColor: '#fff',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  };

  const activeButtonStyle = {
    ...buttonStyle,
    background: "linear-gradient(90deg, #6a63ff, #876cff)", // Purple background for active state
    color: '#fff',
  };

  const ecomButtonStyle = {
    ...buttonStyle,
    background: hoveredButton ? '#fff' : "linear-gradient(90deg, #6a63ff, #876cff)",
    color: hoveredButton ? 'black' : '#fff',
  };

  return (
    <Container fluid style={{ backgroundColor: '#f9f9f9', padding: '6% 0' }}
      data-aos="fade-up"
      data-aos-duration="7000" // 7 seconds duration
    >
      <Container>
        <Row>
          <Col lg={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <h5 style={{ color: 'var(--primary)', fontWeight: '600', marginBottom: '20px', fontSize: '15px' }}
              data-aos="fade-up"
              data-aos-duration="1000" // 1 second duration
            >
              WHAT WE PROVIDE -
            </h5>
            <h3 style={{ fontWeight: 'bold', marginBottom: '20px' }}
              data-aos="fade-up"
              data-aos-duration="1000"
            >Extensive IT Solution Expertise</h3>
            <p style={{ color: '#6c6c6c', marginBottom: '40px' }} className="text">
              Leveraging years of experience to provide comprehensive IT solutions that meet the unique challenges of your business.
            </p>
            <Row>
              <Col md={6}>
                <Button
                  variant="light"
                  style={ecomButtonStyle}
                >
                  E-Commerce Development
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'Web Design' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Web Design')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  Web Design
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'WordPress Development' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('WordPress Development')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  WordPress Development
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'Network Monitoring' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Network Monitoring')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  Network Monitoring
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'Page Speed Optimization' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Page Speed Optimization')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  Page Speed Optimization
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  variant="light"
                  style={hoveredButton === 'Shopify Development' ? activeButtonStyle : buttonStyle}
                  onMouseEnter={() => setHoveredButton('Shopify Development')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  Shopify Development
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="d-none d-lg-block">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={i1} alt="IT Solutions Illustration" style={{ maxWidth: '100%', height: 'auto' }} />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Provide;
