import React from 'react'
import PortfolioIntro from '../components/Portfolio/PortfolioIntro'
import PortfolioTabs from '../components/Portfolio/PortfolioTabs'
import Footer from '../components/Footer'
import FAQs from '../components/FAQs'
import CollabLogo from '../components/CollabLogo'

const PortfolioPage = () => {
  return (
    <div>
      <PortfolioIntro/>
      <PortfolioTabs/>
      <CollabLogo/>

      <FAQs/>
      
      <Footer/>
    </div>
  )
}

export default PortfolioPage
