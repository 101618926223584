import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import logo1 from "../assets/logo/logo1.avif";
import logo2 from "../assets/logo/logo2.avif";
import logo3 from "../assets/logo/logo3.avif";
import logo4 from "../assets/logo/logo4.avif";
import logo5 from "../assets/logo/logo5.avif";
import logo6 from "../assets/logo/logo6.avif";
import logo7 from "../assets/logo/logo7.avif";
import logo8 from "../assets/logo/logo8.avif";
import logo9 from "../assets/logo/logo9.avif";
import logo10 from "../assets/logo/logo10.avif";
import logo11 from "../assets/logo/logo11.avif";
import logo12 from "../assets/logo/logo12.jpg";
import logo13 from "../assets/logo/logo13.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
const CollabLogo = () => {
  useEffect(() => {
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000, // Adjust speed for transition effect
    autoplaySpeed: 2000, // Time for each slide to be fully visible
    cssEase: "linear", // Smooth continuous scrolling
    responsive: [
      {
        breakpoint: 1024, // for tablet screens
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // for mobile screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  // Array of logo images
  const logos = [
    logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11,logo12,logo13
  ];

  return (
    <Container style={{padding:"7% 0"}} 
     data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
>
     
<h2 className='sub-head'
 data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>Trusted Clients</h2>
   
      <Slider {...settings }  style={{margin:"8rem 0"}}>
        {logos.map((logo, index) => (
          <div className="collab-logo" key={index} 
           data-aos="fade-up"
      data-aos-duration="4000" // 2 seconds duration
>
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default CollabLogo;
