import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import IntroSect from "../compCards/IntroSect";

const AboutIntro = () => {


  return (
    <div
      className="intro"
      style={{
      
      }}
    >
      <Container fluid
        style={{
        //   backgroundImage: `url(${intro})`,
          textAlign: "center",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // borderRadius: "20px",
          padding: "60px 20px",
          height:"100%",
        }}
      >
          <div className="intro-overlay"></div>
        <Row style={{zIndex:"2"}}>
          <IntroSect
            head1="About Us"
            head2="About Us"
          />
        </Row>

</Container>
    </div>
  );
};

export default AboutIntro;
