

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitterSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from '../assets/logo2.png'
const Footer = () => {
  return (
    <>
      {/* Main footer section */}
      <div style={{ backgroundColor: "var(--darkblue)", padding: "8rem 0 2px 0" }}>
        <Container>
          <Row>
            <Col lg={3} md={2} sm={12} style={{ position: "relative", bottom: "1rem" }}>
              {/* <h4 style={{ color: "white" }}>
                <span style={{ fontWeight: "bold", fontSize: "38px" }}>SMG </span>
                <span style={{ fontWeight: "bold", fontSize: "17px" }}>
                  &nbsp;Arctic Tech
                </span>
              </h4> */}
              <img 
    src={logo} 
    alt="Livia Schröder Logo" 
    className="navlogo" 
    style={{ height: "4rem", marginLeft: "4px", position: "relative", top: "1px" ,width:"8rem"}} 
  />
              {/* <p className="text" style={{ color: "var(--white)" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua
              </p> */}

              <div className="logo-social-media" style={{ margin: "1rem 0" }}>
                <FaFacebook
                  style={{
                    background: "none",
                    color: "var(--white)",
                    border: "1px solid var(--white)",
                    margin: "2px",
                    fontSize: "42px",
                    borderRadius: "50%",
                    marginRight: "4px",
                    padding: "8px",
                  }}
                />

<a
  href="https://www.instagram.com/smgarctictech"
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer"
  style={{ textDecoration: "none" }} // Ensures no underline on the icon
>
  <FaInstagram
    style={{
      background: "none",
      color: "var(--white)",
      border: "1px solid var(--white)",
      margin: "2px",
      fontSize: "42px",
      borderRadius: "50%",
      marginRight: "4px",
      padding: "8px",
    }}
  />
</a>
<a
  href="https://www.linkedin.com/company/smg-arctic-technology/ "
  target="_blank" // Opens the link in a new tab
  rel="noopener noreferrer"
  style={{ textDecoration: "none" }} // Ensures no underline on the icon
>
  <FaLinkedinIn
    style={{
      background: "none",
      color: "var(--white)",
      border: "1px solid var(--white)",
      margin: "2px",
      fontSize: "42px",
      borderRadius: "50%",
      marginRight: "4px",
      padding: "8px",
    }}
  />
</a>


               
              </div>
            </Col>

            <Col lg={3} md={2} sm={12}>
              <h5 style={{ color: "var(--white)" }}>
                <b style={{ marginBottom: "16px" }}>Contact Info</b>
              </h5>
              {/* <ul
                style={{
                  fontSize: "0.9rem",
                  listStyle: "none",
                  paddingLeft: "0px",
                  color: "var(--white)",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                  <i
                    className="fas fa-map-marker-alt"
                    style={{ marginRight: "8px", color: "var(--primary)" }}
                  ></i>
                  K7-Glomac Cyberjaya, 63000 Malaysia

                </li>
                <li style={{ marginBottom: "10px" }}>
                  <i
                    className="fas fa-phone-alt"
                    style={{ marginRight: "8px", color: "var(--primary)" }}
                  ></i>
                   +6011-6353-3992

                </li>
                <li style={{ marginBottom: "10px" }}>
                  <i
                    className="fas fa-envelope"
                    style={{ marginRight: "8px", color: "var(--primary)" }}
                  ></i>
                   info@smgarctic.com
                </li>
              </ul> */}

              <ul
  style={{
    fontSize: "0.9rem",
    listStyle: "none",
    paddingLeft: "0px",
    color: "var(--white)",
  }}
>
  <li style={{ marginBottom: "10px" }}>
    <a
      href="https://www.google.com/maps/search/?api=1&query=K7-Glomac+Cyberjaya,+63000+Malaysia"
      target="_blank" // Opens the map in a new tab
      rel="noopener noreferrer"
      style={{ color: "var(--white)", textDecoration: "none" }}
    >
      <i
        className="fas fa-map-marker-alt"
        style={{ marginRight: "8px", color: "var(--primary)" }}
      ></i>
      K7-Glomac Cyberjaya, 63000 Malaysia
    </a>
  </li>

  <li style={{ marginBottom: "10px" }}>
    <a
      href="tel:+601163533992"
      style={{ color: "var(--white)", textDecoration: "none" }}
    >
      <i
        className="fas fa-phone-alt"
        style={{ marginRight: "8px", color: "var(--primary)" }}
      ></i>
      +6011-6353-3992
    </a>
  </li>

  <li style={{ marginBottom: "10px" }}>
    <a
      href="mailto:info@smgarctic.com"
      style={{ color: "var(--white)", textDecoration: "none" }}
    >
      <i
        className="fas fa-envelope"
        style={{ marginRight: "8px", color: "var(--primary)" }}
      ></i>
      info@smgarctic.com
    </a>
  </li>
</ul>

            </Col>

            <Col lg={3} md={2} sm={12}>
             
              <Row>
              <h5 style={{ color: "var(--white)" }}>
                <b style={{ marginBottom: "5px" }}>Our Services</b>
              </h5>
                <Col lg={6} md={12} sm={12}>
                <ul
                style={{
                  fontSize: "0.9rem",
                  listStyle: "none",
                  paddingLeft: "0px",
                  color: "var(--white)",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                <Link to='/services?service=Digital Marketing' style={{color: "var(--white)", textDecoration:"none"}}> 
                Digital Marketing</Link>
               
                </li>
                <Link to='/services?service=Generative AI' style={{color: "var(--white)", textDecoration:"none"}}> Generative AI</Link>
                <li style={{ marginBottom: "10px" }}>
             </li>
                <li style={{ marginBottom: "10px" }}>
                <Link to='/services?service=App Development' style={{color: "var(--white)", textDecoration:"none"}}> App Development </Link>
                </li>
                <li style={{ marginBottom: "10px", whiteSpace: "nowrap" }}>
                <Link to='/services?service=UI / UX Design' style={{color: "var(--white)", textDecoration:"none"}}>
                UI / UX
                </Link>
               </li>
                <li style={{ marginBottom: "10px" }}>
                <Link to='/services?service=Website Development' style={{color: "var(--white)", textDecoration:"none"}}>   Web Development</Link>
              </li>
              </ul>
                </Col>


                <Col lg={6} md={12} sm={12}>
                <ul
                style={{
                  fontSize: "0.9rem",
                  listStyle: "none",
                  paddingLeft: "0px",
                  color: "var(--white)",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                <Link to='/aboutus' style={{color: "var(--white)", textDecoration:"none"}}> 
                About Us</Link>
               
                </li>
                <Link to='/portfolio' style={{color: "var(--white)", textDecoration:"none"}}> Portfolio</Link>
                <li style={{ marginBottom: "10px" }}>
             </li>
                {/* <li style={{ marginBottom: "10px" }}>
                <Link to='/blog' style={{color: "var(--white)", textDecoration:"none"}}>Blogs </Link>
                </li> */}
                <li style={{ marginBottom: "10px" }}>
                <Link to='/contact' style={{color: "var(--white)", textDecoration:"none"}}> Contact</Link>
               </li>
            
              </ul>
                </Col>
              </Row>
              
            </Col>

            <Col lg={3} md={2} sm={12}>
              <h5 style={{ color: "var(--white)" }}>
                <b style={{ marginBottom: "16px" }}>GET HELP</b>
              </h5>
              <ul
                style={{
                  fontSize: "0.9rem",
                  listStyle: "none",
                  paddingLeft: "0px",
                  color: "var(--white)",
                }}
              >
                <li style={{ marginBottom: "10px" }}>
                
                <Link to='/refund-policy' style={{color: "var(--white)", textDecoration:"none"}}>  Refund Policy</Link>
                </li>
                <li style={{ marginBottom: "10px" }}>
                <Link to='/privacy-policy' style={{color: "var(--white)", textDecoration:"none"}}>  Privacy Policy</Link>
               
              </li>
                <li style={{ marginBottom: "10px" }}>
                  <Link to='/terms-and-conditions' style={{color: "var(--white)", textDecoration:"none"}}>Terms and Conditions</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <p
        style={{
          paddingTop:"8rem",
          textAlign: "center",
          // position: "sticky", // Fixes the footer at the bottom
          // bottom: "0", // Positions it at the very bottom
          // left: "0", // Aligns to the left
          width: "100%", // Spans the entire width of the screen
          padding: "0.5rem 0", // Adds some padding for vertical spacing
          backgroundColor: "var(--darkblue)",
          zIndex: "1000", // Ensures it stays on top of other content
          display: "flex", // Flexbox for centering
          justifyContent: "center", // Horizontally centers content
          alignItems: "center", // Vertically centers content
        }}
      >
        <span style={{ color: "var(--white)" }}>
        © 2024 SMG ARCTIC TECH
        </span>
      </p>
      </div>

   
    </>
  );
};

export default Footer;
