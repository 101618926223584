import React from 'react'
import PrivacyPolicyIntro from '../components/PrivacyPolicyIntro'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Footer from '../components/Footer'

const PrivacyPolicyPage = () => {
  return (
    <div>
      <PrivacyPolicyIntro/>
      <PrivacyPolicy/>
      <Footer/>
    </div>
  )
}

export default PrivacyPolicyPage
