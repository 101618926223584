import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import IntroSect from "./compCards/IntroSect";

const RefundPolicyIntro = () => {
  return (
    <div
    className="intro"
    style={{
      // backgroundColor: "#f9f1f1",
      // borderRadius: "20px",
      // padding: "20px",
    }}>
    <Container fluid
      style={{
      //   backgroundImage: `url(${intro})`,
        textAlign: "center",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // borderRadius: "20px",
        padding: "60px 20px",
        height:"100%",
      }}
    >
        <div className="intro-overlay"></div>
      <Row style={{zIndex:"2"}}>
        <IntroSect
          head1="Refund Policy "
          head2="Refund Policy"
        />
      </Row>
</Container>
  </div>
  )
}

export default RefundPolicyIntro
