import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaBars } from 'react-icons/fa'; // Importing the Font Awesome icon component
import logo from '../assets/logo2.png'
function Navbarr() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const handleNavLinkClick = () => {
    console.log("Before:", expanded);
    setExpanded(false);
    console.log("After:", expanded);
  };
  return (
    <Navbar
      expand="lg"
      className=""
      expanded={expanded}
      style={{
        borderBottom: "2px solid var(--white)",
        backgroundColor: "var(--white)",
        position: "fixed",
        width: "100%",
        top: 0,
        zIndex: 1000
      }}
    >
      <Container >
      <Navbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
  <img 
    src={logo} 
    alt="Livia Schröder Logo" 
    className="navlogo" 
    style={{ height: "4rem", marginLeft: "4px", position: "relative", top: "1px" ,width:"8rem"}} 
  />
</Navbar.Brand>

        <Navbar.Toggle 
          aria-controls="navbarScroll" 
          onClick={handleToggle} 
          style={{marginRight:"5px", border: expanded ? "none" : "none"}}> 
          {/* Applying blue border color */}
          <FaBars /> 
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto  my-lg-0 nav-items" navbarScroll>
          
          <Nav.Link as={Link} to="/" onClick={() => handleNavLinkClick("home")}>Home</Nav.Link>
<Nav.Link as={Link} to="/aboutus" onClick={() => handleNavLinkClick("about")}>About Us</Nav.Link>
<Nav.Link as={Link} to="/services" onClick={() => handleNavLinkClick("speaking")}>Services</Nav.Link>
<Nav.Link as={Link} to="/portfolio" onClick={() => handleNavLinkClick("consulting")}>PortFolio</Nav.Link>
{/* <Nav.Link as={Link} to="/blog" onClick={() => handleNavLinkClick("blog")}>Blog</Nav.Link> */}
<Nav.Link as={Link} to="/contact" onClick={() => handleNavLinkClick("contact")}>Contact</Nav.Link>
 </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbarr;