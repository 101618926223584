

import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaLaptopCode,
  FaMobileAlt,
  FaCogs,
  FaBullhorn,
  FaPalette,
  FaRobot,
  FaServer,
  FaPaintBrush,
  FaProductHunt,
} from "react-icons/fa";
import ServiceCard from "./compCards/ServiceCard";
import Aos from "aos";
import "aos/dist/aos.css";
const Services = () => {


  const services = [
    {
      id: "1",
      icon: <FaLaptopCode size={50} />,
      title: "Website Development",
      description:
        "Crafting responsive and dynamic websites that enhance user experience and drive business growth.",
        readmore: "/services?service=Website Development",
 
    },
    {
      id: "2",
      icon: <FaMobileAlt size={50} />,
      title: "Mobile App Development",
      description:
        "Building intuitive and feature-rich mobile apps tailored to meet your business needs across iOS and Android platforms.",
        readmore: "/services?service=App Development",
  
      },
    {
      id: "3",
      icon: <FaCogs size={50} />,
      title: "Custom Software Development",
      description:
        "Delivering bespoke software solutions that align with your unique business processes and objectives.",
        readmore: "/services?service=Custom Software Development",
  
      },
    {
      id: "4",
      icon: <FaBullhorn size={50} />,
      title: "Digital Marketing",
      description:
        "Boosting your online presence through targeted digital marketing strategies that convert leads into loyal customers.",
        readmore: "/services?service=Digital Marketing",
  
    },
    {
      id: "5",
      icon: <FaPalette size={50} />,
      title: "UI/UX Design",
      description:
        "Designing visually compelling and user-centric interfaces that elevate the overall user experience of your digital products.",
        readmore: "/services?service=UI/UX",
  
    },
    {
      id: "6",
      icon: <FaRobot size={50} />,
      title: "Generative AI",
      description:
        "Leveraging AI to create innovative solutions that automate tasks, generate content, and optimize decision-making processes.",
        readmore: "/services?service=Generative AI",
  
    },
    {
      id: "7",
      icon: <FaServer size={50} />,
      title: "DEVOPS",
      description:
        "Streamlining development and operations with our DevOps services for faster, more reliable software delivery.",
        readmore: "/services?service=DEVOPS",
  
    },
    {
      id: "8",
      icon: <FaPaintBrush size={50} />,
      title: "Graphics Designing",
      description:
        "Creating visually stunning designs that effectively communicate your brand’s message and captivate your audience.",
        readmore: "/services?service=Graphic Designing",
  
    },
    {
      id: "9",
      icon: <FaProductHunt size={50} />,
      title: "Product Development",
      description:
        "Transforming ideas into market-ready products through comprehensive development and strategic planning.",
        readmore: "/services?service=Product Development",
  
    },
  ];


  // useEffect(() => {
  //   Aos.init({ duration: 7000 });
  // }, []);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Container style={{ padding: "7% 0" }}>
      <h3 className="head"
      data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>WHAT WE DO -</h3>
      <h2 className="sub-head" 
       data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>Our Services</h2>
      <Row style={{ margin: "0 10%" }}>
        {services.map((service, index) => (
          <Col md={4} key={index} style={{ margin: "10px 0" }} 
           data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
> 
            <ServiceCard
              id={service.id}
              icon={service.icon}
              title={service.title}
              description={service.description}
              readmore={service.readmore}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
