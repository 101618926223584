import React from "react";
import { useParams } from "react-router-dom";

const ServiceDetail = () => {
  const { id } = useParams();

  console.log("ID from URL:", id);

  const services = [
    {
      id: "1",
      title: "Web Development",
      description: "Full description of Web Development services.",
      readmore: "/services?service=Web Development",
    },
    {
      id: "2",
      title: "IT Solutions",
      description: "Full description of IT Solutions services.",
      readmore: "/services?service=IT Solutions",
    },
    {
      id: "3",
      title: "Networking Services",
      description: "Full description of Networking Services.",
      readmore: "/services?service=Networking Services",
    },
    {
      id: "4",
      title: "Cyber Security",
      description: "Full description of Cyber Security services.",
      readmore: "/services?service=Cyber Security",
    },
    {
      id: "5",
      title: "Cloud Computing",
      description: "Full description of Cloud Computing services.",
      readmore: "/services?service=Cloud Services",
    },
    {
      id: "6",
      title: "App Development",
      description: "Full description of App Development services.",
      readmore: "/services?service=App Development",
    },
  ];

  const service = services.find((service) => service.id === id);

  console.log("Found service:", service);

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <div style={{ padding: "20px", margin: "10rem 0 0 0" }}>
      <h2>{service.title}</h2>
      <p>{service.description}</p>
    </div>
  );
};

export default ServiceDetail;
