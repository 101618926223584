
import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaStar } from 'react-icons/fa';
import { Container, Row } from 'react-bootstrap';
import Aos from "aos";
import "aos/dist/aos.css";
// Sample testimonial data
const testimonials = [
  {
    id: 1,
    rating: 5,
    quote: "SMG Arctic Tech transformed our digital strategy with innovative solutions that boosted our efficiency and client engagement. Truly top-notch service!"
  },
  {
    id: 2,
    rating: 4,
    quote: "Our experience with SMG Arctic Tech was seamless from start to finish. Their team was responsive, professional, and delivered exactly what we needed."
  },
  {
    id: 3,
    rating: 5,
    quote: "The creativity and technical expertise at SMG Arctic Tech are unmatched. They took our vision and made it a reality with precision and flair."
  },
  {
    id: 4,
    rating: 4,
    quote: "Thanks to SMG Arctic Tech, our online presence has never been stronger. Their solutions have given us a competitive edge in our market."
  },
  {
    id: 5,
    rating: 5,
    quote: "The team at SMG Arctic Tech exceeded our expectations. Their commitment to quality and understanding of our business needs was evident in the final product."
  },
  {
    id: 6,
    rating: 5,
    quote: "SMG Arctic Tech delivered a custom solution that was perfectly aligned with our goals. Their attention to detail and technical know-how were impressive."
  },
  {
    id: 7,
    rating: 5,
    quote: "Working with SMG Arctic Tech was a game-changer for our business. Their innovative approach and flawless execution made all the difference."
  },
  {
    id: 8,
    rating: 5,
    quote: "We couldn’t be happier with the results from SMG Arctic Tech. Their team was professional, efficient, and incredibly easy to work with."
  },
  {
    id: 9,
    rating: 5,
    quote: "SMG Arctic Tech’s ability to blend creativity with technical precision is truly outstanding. They brought our ideas to life in ways we never imagined."
  },
  {
    id: 10,
    rating: 5,
    quote: "The solutions provided by SMG Arctic Tech have revolutionized the way we operate. Their expertise and dedication are unparalleled."
  },
  {
    id: 11,
    rating: 5,
    quote: "SMG Arctic Tech is the partner you need for your digital projects. They deliver high-quality results that drive real business value."
  },
  {
    id: 12,
    rating: 5,
    quote: "The team at SMG Arctic Tech understood our needs perfectly and delivered a solution that exceeded our expectations. Their service is exceptional."
  },
  {
    id: 13,
    rating: 5,
    quote: "SMG Arctic Tech provided us with a cutting-edge solution that has significantly enhanced our business operations. Their work is top-tier."
  },
  {
    id: 14,
    rating: 5,
    quote: "From concept to execution, SMG Arctic Tech was with us every step of the way. Their commitment to our success was evident in everything they did."
  },
  {
    id: 15,
    rating: 5,
    quote: "Choosing SMG Arctic Tech was the best decision we made for our digital transformation. Their expertise and professionalism are second to none."
  }
];

// Rating stars component
const RatingStars = ({ rating }) => (
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
    {Array.from({ length: 5 }, (_, index) => (
      <FaStar key={index} color={index < rating ? "#ffc107" : "#e4e5e9"} />
    ))}
  </div>
);

const Testimonial = () => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  useEffect(() => {
  }, []);

  return (
    <Container fluid style={{    backgroundColor:"#fcfcfc"}} 
     data-aos="fade-up"
     data-aos-duration="7000">
    <Container style={{ width: '100%',
    backgroundColor:"#fcfcfc"
     }}>
    
    <Row style={{margin: '0 5%',padding:"10% 0"}} >
     
    <h3 className='head'  data-aos="fade-up"
      data-aos-duration="1000">
      TESTIMONIAL -
    </h3>
<h2 className='sub-head'  data-aos="fade-up"
      data-aos-duration="1000">Our Client Say</h2>
  <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={false}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all 1s"
        transitionDuration={1000}
        containerClass="carousel-container"
        arrows={false} 
        
      >
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} style={{ padding: '20px' }}>
            <div style={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              padding: '40px 20px',
              height:"100%"
            }}>
              <p style={{ fontStyle: 'italic', color: '#555' }} className='text'>
                "{testimonial.quote}"
              </p>
              <RatingStars rating={testimonial.rating} />
            

            </div>
          </div>
        ))}
      </Carousel>
      </Row>
 
 
      </Container>   </Container>
  );
};

export default Testimonial;
