

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const ContactMap = () => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 2.923441,  // Latitude for K7-Glomac Cyberjaya, Malaysia
    lng: 101.655874, // Longitude for K7-Glomac Cyberjaya, Malaysia
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={12}>
          <LoadScript googleMapsApiKey="AIzaSyCh39n5U-4IoWpsVGUHWdqB6puEkhRLdmI">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={15} // Adjusted zoom for closer view
              center={defaultCenter}
            >
              <Marker position={defaultCenter} />
            </GoogleMap>
          </LoadScript>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactMap;
