
import React from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import PopularPosts from "./BlogsPopularPost"; // Import the new Sidebar component
import t1 from "../assets/blogs.jpg";
import t2 from "../assets/blogs2.jpg";
import t3 from "../assets/blogs3.jpg";
const BlogPost = () => {
  const location = useLocation();
  const { blog } = location.state;

    const popularPosts = [
    {
      image: t1,
     head: "Performance Crew Fleece",
      
      date: "01 March, 2023",
      
    },
    {
      image: t2,
      head: "Performance Crew Fleece",
     
      date: "01 March, 2023",
        },
    {
      image: t3,
      head: "Improving Business Performance With AI",
      date: "January 15, 2023",
    },
  ];

  return (
    <div className="container my-5" style={{ paddingTop: "5rem" }}>
      <div className="row">
        {/* Main Blog Post Section */}
        <div className="col-md-8">
          <div className="card shadow-sm p-4">
            {/* Main Image */}
            <img
              src={blog.image}
              alt={blog.head}
              className="img-fluid mb-4"
              style={{ height: "400px", width: "100%", objectFit: "cover" }}
            />

            {/* Blog Post Metadata */}
            <div className="text-muted mb-2">
              <small>Posted On: {blog.date} </small> |{" "}
              <small>Posted By: {blog.author}</small>
            </div>

            {/* Blog Title */}
            <h1 className="display-5 mb-4 sub-head" style={{ textAlign: "left" }}>
              {blog.head}
            </h1>

            {/* Blog Content */}
            <p className="text mb-5">{blog.content}</p>

            {/* Social Sharing and Tags */}
            <div className="d-flex justify-content-between align-items-center">
              {/* Tags */}
              <div className="d-flex">
                <span className="badge bg-primary me-2">Technology</span>
                <span className="badge bg-primary me-2">Solutions</span>
              </div>

              {/* Social Share */}
              <div className="d-flex align-items-center">
                <span className="me-2">Share: </span>
                <FaFacebook className="me-3" />
                <FaTwitter className="me-3" />
                <FaLinkedin />
              </div>
            </div>
          </div>
        </div>

        {/* Sidebar Section */}
        <div className="col-md-4">
          <PopularPosts posts={popularPosts} />
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
