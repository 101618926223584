import React, { useEffect } from 'react'
import { FaTools, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import service from '../../assets/services2.webp'
import { Container, Row, Col, Image } from 'react-bootstrap';
import Aos from "aos";
import "aos/dist/aos.css";
const ServicesFeatures = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Container className="help-desk-section py-5" style={{ backgroundColor: '#fff' }}
    data-aos="fade-up"
      data-aos-duration="2000" // 2 seconds duration
>
    <Row className="justify-content-center text-center mb-4"
     data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration

    >
    <Col lg={6} md={12} sm={12}
    >
    <Image
          src={service} // Path passed as prop
          alt="AI Solutions"
          
          style={{width:"50%",margin:"2rem 0",}}
        />
    
    </Col>
    <Col lg={6} md={12} sm={12}>
        <h2 style={{  marginBottom: '20px' ,textAlign:"left"}} className='sub-head'
         data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>  
          Elevate Productivity with Our Expert Help Desk Services
        </h2>
        {/* <p style={{   marginTop: '10px' ,textAlign:"left" }} className='text'>
          Welcome to Bytewave, your trusted companion in the realm of IoT services. We take pride in being a dedicated partner, committed to delivering state-of-the-art IoT solutions that propel the future of your business.
        </p> */}

        <div className="d-flex align-items-center mb-4">
          <FaTools size={40} style={{ color: '#000', marginRight: '20px',position:"relative",bottom:"10px" }} />
          <div>
            <h6 style={{ fontWeight: '700', fontSize: '14px'  ,textAlign:"left"}}  className='sub-head'>Efficient Technical Support</h6>
            <p  className='text'>Quick and effective resolution of technical issues.</p>
          </div>
        </div>

        <div className="d-flex align-items-center mb-4">
          <FaChartLine size={40} style={{ color: '#000', marginRight: '20px' ,position:"relative",bottom:"10px" }} />
          <div>
            <h6 style={{ fontWeight: '700', fontSize: '14px' ,textAlign:"left"}} className='sub-head'>Enhanced Productivity</h6>
            <p>Time saved through prompt guidance.</p>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <FaShieldAlt size={40} style={{ color: '#000', marginRight: '20px' ,position:"relative",bottom:"10px" }} />
          <div>
            <h6 style={{ fontWeight: '700', fontSize: '14px',textAlign:"left" }}  className='sub-head'>Minimized Incidents</h6>
            <p className='text'>Consistent support to minimize disruptions.</p>
          </div>
        </div>
      </Col>
    </Row>

   

   
  </Container>
  )
}

export default ServicesFeatures
