

import React from "react";
import { Row, Col, Image } from "react-bootstrap";

const PopularPosts = ({ posts }) => {
  return (
    <div className="popular-posts" style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px",padding:"20px",backgroundColor:"#fff8f8"}}>
      <h4 style={{ fontWeight: "bold", marginBottom: "20px" }}>Popular Posts</h4>
      {posts.map((post, index) => (
        <Row key={index} className="mb-3" style={{ alignItems: "center" }}>
          <Col xs={4} style={{ paddingRight: "0px" }}>
            <Image
              src={post.image}
              alt={post.title}
              thumbnail
              fluid
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
          <Col xs={8}>
            <h6
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              {post.head}
            </h6>
            <p style={{ fontSize: "12px", color: "#777", marginBottom: "0px" }}>
              {post.date}
            </p>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default PopularPosts;
