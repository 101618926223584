

import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Container, Row, Col, ProgressBar } from 'react-bootstrap';
import about from '../../assets/about.jpg';


import Aos from "aos";
import "aos/dist/aos.css";
const AboutDevelopment = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [progress, setProgress] = useState({
    technologySolutions: 0,
    successRate: 0,
    potentialClients: 0,
  });

  useEffect(() => {
    if (inView) {
      setProgress({
        technologySolutions: 75,
        successRate: 100,
        potentialClients: 90,
      });
    }
  }, [inView]);


  useEffect(() => {

  }, []);

  return (
    <Container style={{ padding: '7% 10px' }} ref={ref} 
     data-aos="fade-up"
      data-aos-duration="2000" // 2 seconds duration
>
      <Row className="align-items-center">
        {/* Text Section */}
        <Col md={6}>
          <h5 style={{ color: 'var(--primary)', fontWeight: 'bold', fontSize: '15px' }}
          
          
          data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>YOUR CREATIVE WEB DEVELOPMENT PARTNER -</h5>
          <h1 style={{ fontSize: '28px', margin: '20px 0', fontWeight: 'bold' }}
           data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>
            We Provide Innovative Technology And IT Solutions For Your Business
          </h1>
          <p style={{ color: '#6c6c6c' }} className="text"
          >
         
Offering forward-thinking technology and IT solutions tailored to drive your business success and future growth
            </p>

          {/* Progress Bars */}
          <div style={{ marginTop: '30px' }}>
            {/* Technology Solutions */}
            <div style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: 'var(--primary)' }}>Technology Solutions</span>
                <span style={{ color: 'var(--primary)' }}>{progress.technologySolutions}%</span>
              </div>
              <ProgressBar
                now={progress.technologySolutions}
                style={{ height: '7px', backgroundColor: '#eee', marginTop: '5px', '--bs-progress-bar-bg': 'var(--primary)' }}
              />
            </div>

            {/* Success Rate */}
            <div style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: 'var(--primary)' }}>Success Rate</span>
                <span style={{ color: 'var(--primary)' }}>{progress.successRate}%</span>
              </div>
              <ProgressBar
                now={progress.successRate}
                style={{ height: '7px', backgroundColor: '#eee', marginTop: '5px', '--bs-progress-bar-bg': 'var(--primary)' }}
              />
            </div>

            {/* Potential Clients */}
            <div style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: 'var(--primary)' }}>Potential Clients</span>
                <span style={{ color: 'var(--primary)' }}>{progress.potentialClients}%</span>
              </div>
              <ProgressBar
                now={progress.potentialClients}
                style={{ height: '7px', backgroundColor: '#eee', marginTop: '5px', '--bs-progress-bar-bg': 'var(--primary)' }}
              />
            </div>
          </div>
        </Col>

        {/* Image Section */}
        <Col md={6} className="text-center">
          <img src={about} alt="IT Solutions" style={{ width: '80%' }} />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutDevelopment;
