import React from 'react'
import { Container } from 'react-bootstrap'


const RefundPolicy = () => {
  return (
    <Container>
      <h3 className="sub-head" style={{textAlign:"left"}}>
Refund Policy
</h3>
<p className='text'>


<span style={{fontSize:"18px"}}>
<br />
<strong>

Cancellation Policy
    
</strong>
<br />
</span>
<br />
The terms hereof shall constitute SMG Arctic Tech (003349596-K)
<br />
(“Company”) cancellation and refund policy in relation to the Services rendered on the Website (“Cancellation and Refund Policy”). Capitalized terms not defined here in shall have the meaning ascribed to them in the Terms of Use.
<br />
• Cancellation requests may be submitted by email or phone within 24 hours.
<br />
• The company will require (3) working days to process your cancellation request.
<br /><br />
<span style={{fontSize:"18px"}}>
<strong>

Refund Policy
</strong>
</span>
<br />
<br />Please note that refunds only apply to cancellation of services plans that have not been processed and are purchased at https://smgarctic.com operated by SMG Arctic Tech. Due to the nature of our business and products we design, program, editing we will be unable to providerefunds for digital copies of our product once the e-copy has been provided.
<br /><br />
For other Cancellation and Refund Enquiries
<br /><br />
If you have any questions or concerns about our cancellation and refund policy, please email our customer support team at smgarctictech@gmail.com or call us at
<br /><br />
(+60) 1163533992
<br /><br />
<span style={{fontSize:"18px"}}>
<strong>

Contact Us
</strong>
    
</span>
<br />
If you have any questions about our Returns and Refunds Policy, please contact us by e-mail 
<a href="info@smgarctictech.com" style={{color:"#252627"}}>info@smgarctictech.com</a>

</p>
    </Container>
  )
}

export default RefundPolicy
