
import React, { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import s2 from '../../assets/services/Appdevelopment.avif';
import s3 from '../../assets/services/devops.jpg';
import s4 from '../../assets/services/dm.jpeg';
import s5 from '../../assets/services/graphicdesign.jpg';
import s6 from '../../assets/services/pd.jpeg';
import s7 from '../../assets/services/swdevlopment.jpeg';
import s8 from '../../assets/services/uiux.avif';
import s9 from '../../assets/services/webdevelopment.jpg';




import a1 from '../../assets/MAB_web/1.PNG'
import a2 from '../../assets/MAB_web/2.PNG'
import a3 from '../../assets/MAB_web/3.PNG'
import a4 from '../../assets/MAB_web/4.PNG'
import a5 from '../../assets/MAB_web/5.PNG'
import a6 from '../../assets/MAB_web/6.PNG'



import b1 from '../../assets/Restaurant/Checkout.png'
import b2 from '../../assets/Restaurant/Form 5.png'
// import b3 from '../../assets/Restaurant/Food Details .png'
import b4 from '../../assets/Restaurant/Order Details 2.png'


// restarant mobile
import c1 from '../../assets/RestaurantMobile/1.png'
import c2 from '../../assets/RestaurantMobile/2.png'
import c4 from '../../assets/RestaurantMobile/3.png'
import c5 from '../../assets/RestaurantMobile/4.png'



// resutauatnt Admin
import d1 from '../../assets/RestaurantAdmin/Home Dashboard-1.png'
import d2 from '../../assets/RestaurantAdmin/Home Dashboard-10.png'
import d3 from '../../assets/RestaurantAdmin/Home Dashboard-11.png'
import d4 from '../../assets/RestaurantAdmin/Home Dashboard-2.png'
import d5 from '../../assets/RestaurantAdmin/Home Dashboard-3.png'
import d6 from '../../assets/RestaurantAdmin/Home Dashboard-4.png'
import d7 from '../../assets/RestaurantAdmin/Home Dashboard-5.png'
import d8 from '../../assets/RestaurantAdmin/Home Dashboard-6.png'
import d9 from '../../assets/RestaurantAdmin/Home Dashboard-7.png'
import d10 from '../../assets/RestaurantAdmin/Home Dashboard-8.png'
import d11 from '../../assets/RestaurantAdmin/Home Dashboard-9.png'


import e1 from '../../assets/coviaportfolio/1.PNG'

import e2 from '../../assets/coviaportfolio/2.PNG'
import e3 from '../../assets/coviaportfolio/3.PNG'
import e4 from '../../assets/coviaportfolio/4.PNG'
import e5 from '../../assets/coviaportfolio/5.PNG'



import f2 from '../../assets/Uniten/2.png'
import f3 from '../../assets/Uniten/3.png'
import f4 from '../../assets/Uniten/4.png'
import f5 from '../../assets/Uniten/5.png'
import Aos from "aos";
import "aos/dist/aos.css";
const PortfolioTabs = () => {
  const [show, setShow] = useState(false);
  const [activeProject, setActiveProject] = useState(null);

  const projects = [
    { id: 1, name: 'MALAYSIAN ASSOCIATION FOR THE BLIND WEBAPP', images: [a1,a2,a3,a4,a5,a6] },
    { id: 2, name: 'Restaurant’s Customer’s Order System', images: [b1,b2,b4] },
    { id: 3, name: 'Restaurant Mobile App', images: [c1,c2,c4,c5] },
    { id: 4, name: 'Restaurant Admin Dashboard', images: [d1,d2,d3,d4,d5,d6,d7,d8,d9,d10,d11]},
      { id: 5, name: 'COVIA Portfolio', images: [e1,e2,e3,e4,e5]}
   ,
   { id: 6, name: 'Uniten', images: [f3,f2,f4,f5]}
    
    // Add more projects as needed
  ];

  const handleShow = (project) => {
    setActiveProject(project);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  useEffect(() => {

  }, []);
  return (
    <Container className="portfolio-tabs">
      <div className="row" 
      
      data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
>
        {projects.map((project) => (
          <div className="col-md-4 mb-4"
       
            key={project.id} onClick={() => handleShow(project)}>
            <div className="portfolio-item " >
            <div style={{ height: "23rem", width: "23rem", overflow: "hidden", border: "1px solid #ccc" }}>
      <img
        src={project.images[0]}
        alt={project.name}
        className="img-fluid"
        style={{ height: "100%", width: "100%", objectFit: "contain" }}
      />
    </div>


              <p>{project.name}</p>
            </div>
          </div>
        ))}
      </div>

      {/* <Modal show={show} onHide={handleClose} size="lg" centered  >
  <Modal.Body className="p-0" style={{background:"none"}}>
    {activeProject && (
      <Slider {...sliderSettings}>
        {activeProject.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} className="img-fluid w-100" style={{ height: "auto", maxHeight: "70vh" }} />
          </div>
        ))}
      </Slider>
    )}
  </Modal.Body>
</Modal> */}
<Modal show={show} onHide={handleClose} size="lg" centered>
  <Modal.Body className="p-0" style={{ background: "none" }}>
    {activeProject && (
      <Slider {...sliderSettings}>
        {activeProject.images.map((image, index) => (
          <div key={index}>
            {/* Apply phone frame for c1, c2, and c3 */}
            {activeProject.name === 'Restaurant Mobile App' ? ( 
              <div className="phone-frame">
                <div className="phone-screen">
                  <img
                    src={image}
                    alt={`Slide ${index}`}
                    className="img-fluid"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                      borderRadius: "20px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <img
                src={image}
                alt={`Slide ${index}`}
                className="img-fluid w-100"
                style={{ height: "auto", maxHeight: "70vh" }}
              />
            )}
          </div>
        ))}
      </Slider>
    )}
  </Modal.Body>
</Modal>

    </Container>
  );
};

export default PortfolioTabs;
