import React, { useEffect, useState } from 'react';
import FAQsItem from './FAQsItem';import Aos from "aos";
import "aos/dist/aos.css";
const FAQs = () => {
  
  const [openIndex, setOpenIndex] = useState(null); // Track which FAQ is open

  const faqsData = [
    { question: 'What services do you offer for website development?', answer: 'We craft responsive and dynamic websites that enhance user experience and drive business growth.' },
  { question: 'Can you develop mobile apps for both iOS and Android?', answer: 'Yes, we build intuitive and feature-rich mobile apps tailored for iOS and Android platforms.' },
  { question: 'What is custom software development?', answer: 'Custom software development involves delivering bespoke solutions that align with your unique business processes.' },
  { question: 'How can digital marketing help my business?', answer: 'Our digital marketing strategies boost your online presence and convert leads into loyal customers.' },
  { question: 'What does your UI/UX design service include?', answer: 'We design visually compelling and user-centric interfaces to elevate the user experience.' },
   ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the current FAQ or close it
  };
  useEffect(() => {
  }, []);
  return (
    <div style={{padding:"6% 0"}} 
     data-aos="fade-up"
      data-aos-duration="7000" // 2 seconds duration
>
      <h3 className='head'
       data-aos="fade-up"
      data-aos-duration="1000" // 2 seconds duration
>
       Frequently Ask Question -
    </h3>
<h2 className='sub-head'>
Keep Answers Short
</h2>
    <div className="faqs-container">
     
      <div className="faqs">
        {faqsData.map((faq, index) => (
          <FAQsItem
            key={index}
            faq={faq}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </div>
  </div>
  );
};

export default FAQs;
